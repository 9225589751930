body > * {
  font-family: "Sequel Sans", "Helvetica Neue", Arial, "Noto Sans", sans-serif !important;
}

.email-form {
  width: 100%;
}

.thanks-text {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.notify-text {
  font-size: 14px;
  text-align: center;
}
